import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/system/Container'
import React, { useReducer } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const LeadForm = () => {
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const handleSubmit = async evt => {
        evt.preventDefault();
        setLoading(true);
        let data = JSON.stringify(formInput)
        await axios({
            method: 'post',
            url: 'https://mybanarhat.herokuapp.com/addUser',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            })
        .then(function (response) {
            if(response.status === 200){
                setLoading(false);
                setSuccess(true);
            }
        })
        .catch(function (error) {
            setLoading(false);
        });
    }

    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };

    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          name: "",
          email: "",
          phoneNumber: "",
          detail: ""
        }
    );
    return (
        <Container maxWidth="md">
            <Typography variant="h5" component="h3" align='center'>Tell Us Your Thought</Typography>
            <Typography component="p" align='center'>Helps us design it better for you!</Typography>
            <form onSubmit={handleSubmit}>
            <TextField
                id="standard-detail"
                label="What do you expect or suggestion ?"
                name="detail"
                helperText="Some Suggestion"
                variant="standard"
                fullWidth
                multiline
                maxRows={4}
                onChange={handleInput}
            />
            <TextField
                required
                id="standard-required"
                placeholder='Enter Name'
                name='name'
                variant="standard"
                helperText="Required"
                fullWidth
                onChange={handleInput}
            />
            <br />
            <TextField
                id="standard-email-input"
                required
                type="email"
                name="email"
                placeholder='Enter Email'
                variant="standard"
                helperText="Required"
                fullWidth
                onChange={handleInput}
            />
            <br />
            <TextField
                id="standard-number"
                type="tel"
                name="phoneNumber"
                placeholder='Phone Number'
                variant="standard"
                helperText="Required"
                fullWidth
                onChange={handleInput}
            />
            <br />
            <br/>
            {
                loading ?
                    <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                    >
                        Save
                    </LoadingButton>
                    :
                    success ?
                        <Button variant="contained" color="success">
                            Thanks
                        </Button>
                        :
                        <Button variant="contained" type='submit'>
                            &nbsp;&nbsp;Save&nbsp;&nbsp;
                        </Button>
            }
            </form>
            <br/>
        </Container>
    )
}

export default LeadForm