import { Container, Grid } from "@mui/material";
import TagManager from "react-gtm-module";
import "./App.css";
import ImageCard from "./component/ImageCard";
import LeadForm from "./component/LeadForm";
import ResponsiveAppBar from "./component/NavBar";
import imageData from "./imageData.json";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-K7MF4T4",
  };
  TagManager.initialize(tagManagerArgs);
  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="xl" className="container">
        <Grid container spacing={3}>
          {imageData.map((data,key) => {
            return (
              <Grid key={`grid-${key}`} item xs={12} sm={6}>
                <ImageCard key={`image-${key}`} image={data} />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6}>
            <LeadForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default App;
