import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const ImageCard = ({image}) => {
    return (
        <Card sx={{ maxWidth: 600, margin: '0 10px' }}>
            <CardMedia
                component="img"
                alt={image.alt}
                height="300"
                image={image.link}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {image.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                   {image.data}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ImageCard